import { Box } from "atomic-layout"
import PageTitle from "components/layout/pageTitle"
import { getFooterProps } from 'components/layout/footer'
import { getHeaderProps } from 'components/layout/header'
import { getLoaderProps } from 'components/loader'

export default function NotFound() {
  return (
    <Box flexGrow={"1"} flex flexDirection="column" justifyContent="center">
        <PageTitle title={"Sorry, that page was <gradient>not found<gradient>."} />
    </Box>
  )
}

export async function getStaticProps(ctx) {
    return {
        props: {
            footerProps: await getFooterProps(),
            headerProps: await getHeaderProps(),
            loaderProps: await getLoaderProps(),
        }
    }
}
